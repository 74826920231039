// src/components/DashboardHeader.js
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from '../auth0/LoginButton';
import LogoutButton from '../auth0/LogoutButton';

const DashboardHeader = ({ title, icon }) => {
  const { isAuthenticated, user } = useAuth0();
  return (
    <>
      <div>
        <div className="left-left f18">
          <span className="icontop-3 mrl-10">{icon}</span>{title}
        </div>
        <div className="right-right"><span style={{marginRight:"5px"}}>Welcome: {user.name}</span> 
          {isAuthenticated ? (
            <LogoutButton />
          ) : (
            <LoginButton />
          )}
        </div>
        <div className="clear"></div>
      </div>
    </>
  );
};

export default DashboardHeader;
