import React from "react";
import "../css/manageDevices.css";

const ListUsers = ({ users, onEdit, onDelete }) => {
  return (
    <div className="w100">
      <div>
        <div className="device-head">
          <div className="device-details">
            <p>Name</p>
            <p>Email</p>
          </div>
        </div>
        <div className="clear"></div>
      </div>
      {users.map((user, index) => (
        <div className="device-item" key={index}>
          <div className="device-details">
            <p>{user.name}</p>
            <p style={{width:'200px'}}>{user.email}</p>
          </div>
          <div className="device-actions">
            <div>
              <button onClick={() => onEdit(user)}>Edit</button>
            </div>
            <div>
              <button onClick={() => onDelete(user.id)}>Delete</button>
            </div>
          </div>
        </div>
      ))}
      <div className="device-item">
        <div className="device-details">
          <p>Amit</p>
          <p style={{width:'200px'}}>amit-sharma@live.com</p>
        </div>
        <div className="device-actions">
          <div>
            <button>Edit</button>
          </div>
          <div>
            <button className="bg-red">Delete</button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ListUsers;