import { useAuth0 } from '@auth0/auth0-react';

const LoginButton = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  if (isAuthenticated) {
    return null; // Hide the login button if the user is already authenticated
  }
  return (
   loginWithRedirect()
  );
};
export default LoginButton;

