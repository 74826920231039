import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import DashboardFinal from './Pages/Dashboard';
import ThresholdAlert from './Pages/thresholdAlert';
import ManageUsers from './Pages/ManageUsers';
import ManageDevices from './Pages/ManageDevices';
import GeneralSettings from './Pages/GeneralSettings';
import Login from './login'; // Import the Login component
import './App.css';

const App = () => {
  const { isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isLoading) {
      setLoading(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  const handleLogout = () => {
    localStorage.removeItem('sessionId');
    localStorage.removeItem('sessionExpiry');
    logout({ returnTo: window.location.origin });
  };

  useEffect(() => {
    const checkSessionExpiry = () => {
      const sessionExpiry = localStorage.getItem('sessionExpiry');
      if (sessionExpiry && Date.now() > sessionExpiry) {
        handleLogout();
      }
    };

    const interval = setInterval(checkSessionExpiry, 1000);
    return () => clearInterval(interval);
  }, [logout]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={isAuthenticated ? <DashboardFinal /> : <div>Redirecting to login...</div>}
        />
        <Route path="/login" element={<Login />} />
        {isAuthenticated && (
          <>
            <Route path="/threshold" element={<ThresholdAlert />} />
            <Route path="/manageUsers" element={<ManageUsers />} />
            <Route path="/manageDevices" element={<ManageDevices />} />
            <Route path="/GeneralSettings" element={<GeneralSettings />} />
          </>
        )}
      </Routes>
      {isAuthenticated && (
        <button onClick={handleLogout}>Logout</button>
      )}
    </>
  );
};

export default App;
