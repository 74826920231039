import React, { useState } from "react";
import Sidebar from "../Components/Sidebar";
import DashboardHeader from "../Components/HeaderDashboard";
import { FaUser, FaPlus, FaMinus } from "react-icons/fa";
import ListUsers from "../Components/ListUsers";
import AddEditUser from "../Components/AddEditUser";
import Modal from 'react-modal';
import "../css/manageUsers.css";

const ManageUsers = () => {
  const [isHidden, setIsHidden] = useState(false);
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingUser, setEditingUser] = useState(null);

  const toggleClass = () => {
    setIsHidden((prev) => !prev);
  };

  const openModal = () => {
    setIsModalOpen(true);
    // trackEvent('Add_Device_Form_Clicked', { source: 'Frontend' }, sessionId);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditingUser(null); // Clear editing user on modal close
  };

  const handleAddEditUser = (user) => {
    if (editingUser) {
      setUsers(users.map((u) => (u.id === user.id ? user : u)));
    } else {
      user.id = users.length + 1;
      setUsers([...users, user]);
    }
    setIsModalOpen(false); // Close form after save
  };

  const handleEditUser = (user) => {
    setEditingUser(user);
    setIsModalOpen(true); // Open form when editing
  };

  const handleDeleteUser = (userId) => {
    setUsers(users.filter((user) => user.id !== userId));
  };

  const handleCancel = () => {
    setIsModalOpen(false); // Close form on cancel
    setEditingUser(null); // Clear editing user on cancel
  };

  const dashboardTitle = "Manage Users"; //for dynamic title on page top bar

  return (
    <div className="App">
      <div className="page-wrapper">
        <Sidebar isHidden={isHidden} toggleClass={toggleClass} />
        <div className={isHidden ? "rightblock w-90" : "rightblock"}>
          <div className="top-card">
            <DashboardHeader title={dashboardTitle} icon={<FaUser />} />
          </div>
          <div className="p20 w-80 mauto">
            <div
              id="add"
              className="f20 cursorpointer m-10"
              onClick={openModal}
            >
              {isModalOpen ? (
                <FaMinus className="icontop f18" />
              ) : (
                <FaPlus className="icontop f18" />
              )}{" "}
              Add a User
            </div>
            <div className="clear"></div>
            <Modal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              contentLabel="Add/Edit User"
            >
              <AddEditUser
                user={editingUser}
                onSave={handleAddEditUser}
                onCancel={handleCancel}
              />
            </Modal>
            <ListUsers
              users={users}
              onEdit={handleEditUser}
              onDelete={handleDeleteUser}
            />
            <div className="clear"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageUsers;
