import React from "react";
import { useState, useEffect, useCallback } from "react";
import Sidebar from "../Components/Sidebar";
import DashboardHeader from "../Components/HeaderDashboard";

const GeneralSettings = () => {
    const [isHidden, setIsHidden] = useState(false); 
    const toggleClass = () => {
      setIsHidden((prev) => !prev);
    };
    const dashboardTitle = "Settings"; //for dynamic title on page top bar
  return (
    <div className="App">
      <div className="page-wrapper">
        <Sidebar isHidden={isHidden} toggleClass={toggleClass} />
        <div className={isHidden ? "rightblock w-90" : "rightblock"}>
          <div className="top-card">
            <DashboardHeader title={dashboardTitle} />
          </div>
          <div className="p20">
            <h3>Manage Settings</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralSettings;