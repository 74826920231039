import React, { useState, useEffect, useRef, useCallback } from "react";
import * as signalR from "@aspnet/signalr";
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from "recharts";
import moment from "moment";
//import { logError, logMessage, trackEvent } from "../Components/logtrigger.js";

const SalesTotal = ({
  deviceData,
  chartData,
  selectedLocation,
  setDeviceData,
  setChartData, 
}) => {
  const [temperatureThreshold, setTemperatureThreshold] = useState(28);
  const [humidityThreshold, setHumidityThreshold] = useState(75);
  const [phLevelThreshold, setPhLevelThreshold] = useState(8);
  const [turgidityThreshold, setTurgidityThreshold] = useState(5);
  const [temperatureActive, setTemperatureActive] = useState(true);
  const [humidityActive, setHumidityActive] = useState(true);
  const [phLevelActive, setPhLevelActive] = useState(true);
  const [turgidityActive, setTurgidityActive] = useState(true);
  const [showThresholds, setShowThresholds] = useState(false);
  const connectionRef = useRef(null);

  const toggleThresholds = () => {
    setShowThresholds((prevState) => !prevState);
  };

  useEffect(() => {
    const protocol = new signalR.JsonHubProtocol();
    //const url = "http://localhost:7253/api"
    const url = "https://azurewaterfunction20240603172039.azurewebsites.net/api"
    //logMessage('Connecting to ' + url, { source: 'Frontend' });
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(url) // Your SignalR endpoint
      .withHubProtocol(protocol)
      .build();

    connection.on("updateSales", (data) => {
      if (selectedLocation && data.deviceId === selectedLocation.id) {
        setDeviceData(data);
        setChartData((prevChartData) => [...prevChartData, data]);
      }
    });
    connection
      .start()
      .then(() => '')
      .catch((err) => '');
    connectionRef.current = connection;
    // Clean up the connection on component unmount
    return () => {
      connection.stop();
    };
  }, [selectedLocation, setDeviceData, setChartData]);

  return (
    <div>
      <div className="content-card">
        <div
          id="setalert"
          className="textright clear cursorpointer textunderline right p10"
          onClick={() => {
            toggleThresholds();
          }}
        >
          Set Alerts
        </div>
        {showThresholds && (
          <div className="thresholds">
            <div className="spanheader">Set Thresholds</div>
            <div className="clear"></div>
            <div className="thresholddiv">
              <p>Temperature:</p>
              <input
                type="number"
                value={temperatureThreshold}
                onChange={(e) =>
                  setTemperatureThreshold(Number(e.target.value))
                }
                disabled={!temperatureActive}
              />
              <label>
                <input
                  type="checkbox"
                  checked={temperatureActive}
                  onChange={(e) => setTemperatureActive(e.target.checked)}
                />
              </label>
            </div>
            <div className="thresholddiv">
              <p>Flow:</p>
              <input
                type="number"
                value={humidityThreshold}
                onChange={(e) => setHumidityThreshold(Number(e.target.value))}
                disabled={!humidityActive}
              />
              <label>
                <input
                  type="checkbox"
                  checked={humidityActive}
                  onChange={(e) => setHumidityActive(e.target.checked)}
                />
              </label>
            </div>
            <div className="thresholddiv">
              <p>pH Level:</p>
              <input
                type="number"
                value={phLevelThreshold}
                onChange={(e) => setPhLevelThreshold(Number(e.target.value))}
                disabled={!phLevelActive}
              />
              <label>
                <input
                  type="checkbox"
                  checked={phLevelActive}
                  onChange={(e) => setPhLevelActive(e.target.checked)}
                />
              </label>
            </div>
            <div className="thresholddiv">
              <p>Turbidity:</p>
              <input
                type="number"
                value={turgidityThreshold}
                onChange={(e) => setTurgidityThreshold(Number(e.target.value))}
                disabled={!turgidityActive}
              />
              <label>
                <input
                  type="checkbox"
                  checked={turgidityActive}
                  onChange={(e) => setTurgidityActive(e.target.checked)}
                />
              </label>
            </div>
            <div className="clear"></div>
          </div>
        )}
      </div>
      <div className="prl20">
        <div
          className="left w-100"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div
            id="temperature-parent"
            className={`box b-shadow w-25 mrl-5 bw5 ${
              temperatureActive && deviceData.temperature > temperatureThreshold
                ? "backgroundRed"
                : ""
            }`}
            style={{ backgroundColor: "#b3deff", marginLeft : "0px" }}
          >
            <div className="box-header">Temperature</div>
            <div className="box-content" id="temperature">
              {deviceData.temperature}
            </div>
          </div>
          <div
            id="humidity-parent"
            className={`box b-shadow w-25 mrl-5 bw5 ${
              humidityActive && deviceData.humidity > humidityThreshold
                ? "backgroundRed"
                : ""
            }`}
            style={{ backgroundColor: "#ffd9d5" }}
          >
            <div className="box-header">Flow</div>
            <div className="box-content" id="humidity">
              {deviceData.humidity}
            </div>
          </div>
          <div
            id="ph-level-parent"
            className={`box b-shadow w-25 mrl-5 bw5 ${
              phLevelActive && deviceData.phLevel > phLevelThreshold
                ? "backgroundRed"
                : ""
            }`}
            style={{ backgroundColor: "#d2fed4" }}
          >
            <div className="box-header">pH Level</div>
            <div className="box-content" id="ph-level">
              {deviceData.phLevel}
            </div>
          </div>
          <div
            id="turgidity-parent"
            className={`box b-shadow w-25 mrl-5 bw5 ${
              turgidityActive && deviceData.turgidity > turgidityThreshold
                ? "backgroundRed"
                : ""
            }`}
            style={{ backgroundColor: "#f2c8f9" }}
          >
            <div className="box-header">Turbidity</div>
            <div className="box-content" id="turgidity">
              {deviceData.turgidity}
            </div>
          </div>
        </div>
        <div className="clear"></div>
        <div className="mtop-20 b-radius-10 b-shadow bg-white">
          <span className="spanheader plr10 pt10">
            Real Time Trend 
          </span>
          <div className="clear"></div>
          <hr className="mrl-5" />
          <LineChart
            width={600}
            height={300}
            data={chartData}
            margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
          >
            <Line type="monotone" dataKey="temperature" stroke="#FF6F61" />
            <Line type="monotone" dataKey="humidity" stroke="#2196F3" />
            <Line type="monotone" dataKey="phLevel" stroke="#4CAF50" />
            <Line type="monotone" dataKey="turgidity" stroke="#9C27B0" />
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="EventEnqueuedUtcTime" tickFormatter={timeStr => moment(timeStr).format('HH:mm')} />
            <YAxis />
          </LineChart>
        </div>
      </div>
    </div>
  );
};

export default SalesTotal;
