import React, { useState } from "react";
import Sidebar from "../Components/Sidebar";
import DashboardHeader from "../Components/HeaderDashboard";
import "../App.css";
import {FaSourcetree} from "react-icons/fa";

const Threshold = () => {
  const [temperatureThreshold, setTemperatureThreshold] = useState(5);
  const [humidityThreshold, setHumidityThreshold] = useState(60);
  const [phLevelThreshold, setPhLevelThreshold] = useState(22);
  const [turgidityThreshold, setTurgidityThreshold] = useState(5);
  const [temperatureActive, setTemperatureActive] = useState(true);
  const [humidityActive, setHumidityActive] = useState(true);
  const [phLevelActive, setPhLevelActive] = useState(true);
  const [turgidityActive, setTurgidityActive] = useState(true);
  const [isHidden, setIsHidden] = useState(false);

  const toggleClass = () => {
    if (isHidden === 0) {
      setIsHidden(1);
    } else {
      setIsHidden(0);
    }
  };
  const dashboardTitle = "Set Alerts"; //for dynamic title on page top bar

  return (
    <>
      <div className="App">
        <div className="page-wrapper">
          <Sidebar isHidden={isHidden} toggleClass={toggleClass} />
          <div className={isHidden === 1 ? "rightblock w-90" : "rightblock"}>
            <div className="top-card">
              <DashboardHeader title={dashboardTitle} icon={<FaSourcetree />} />
            </div>
            <div className="thresholds mauto w-80 mtop-40">
              <div className="spanheader">Set Thresholds</div>
              <div className="clear"></div>
              <div className="thresholddiv">
                <p>Temperature:</p>
                <input
                  type="number"
                  value={temperatureThreshold}
                  onChange={(e) =>
                    setTemperatureThreshold(Number(e.target.value))
                  }
                  disabled={!temperatureActive}
                />
                <label>
                  <input
                    type="checkbox"
                    checked={temperatureActive}
                    onChange={(e) => setTemperatureActive(e.target.checked)}
                  />
                  Active
                </label>
              </div>
              <div className="thresholddiv">
                <p>Humidity:</p>
                <input
                  type="number"
                  value={humidityThreshold}
                  onChange={(e) => setHumidityThreshold(Number(e.target.value))}
                  disabled={!humidityActive}
                />
                <label>
                  <input
                    type="checkbox"
                    checked={humidityActive}
                    onChange={(e) => setHumidityActive(e.target.checked)}
                  />
                  Active
                </label>
              </div>
              <div className="thresholddiv">
                <p>pH Level:</p>
                <input
                  type="number"
                  value={phLevelThreshold}
                  onChange={(e) => setPhLevelThreshold(Number(e.target.value))}
                  disabled={!phLevelActive}
                />
                <label>
                  <input
                    type="checkbox"
                    checked={phLevelActive}
                    onChange={(e) => setPhLevelActive(e.target.checked)}
                  />
                  Active
                </label>
              </div>
              <div className="thresholddiv">
                <p>Turgidity:</p>
                <input
                  type="number"
                  value={turgidityThreshold}
                  onChange={(e) =>
                    setTurgidityThreshold(Number(e.target.value))
                  }
                  disabled={!turgidityActive}
                />
                <label>
                  <input
                    type="checkbox"
                    checked={turgidityActive}
                    onChange={(e) => setTurgidityActive(e.target.checked)}
                  />
                  Active
                </label>
              </div>
              <div className="clear"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Threshold;
