import React, { useState, useEffect } from "react";

const AddEditDevice = ({ device, onSave, onCancel }) => {
  const [formData, setFormData] = useState({ deviceId: "", DeviceName: "", Area: "", Location: "" });

  useEffect(() => {
    if (device) {
      setFormData(device);
    } else {
      setFormData({ deviceId: "", DeviceName: "", Area: "", Location: "" });
    }
  }, [device]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
    setFormData({ deviceId: "", DeviceName: "", Area: "", Location: "" });
  };

  return (
    <div className="add-edit-device">
      <h3>{device ? "Edit Device" : "Add Device"}</h3>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Device ID:</label>
          <input
            type="text"
            name="deviceId"
            value={formData.deviceId}
            onChange={handleChange}
            required
            disabled={!!device} // Disable the field when editing
          />
        </div>
        <div>
          <label>Device Name:</label>
          <input
            type="text"
            name="DeviceName"
            value={formData.DeviceName}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Area:</label>
          <input
            type="text"
            name="Area"
            value={formData.Area}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Location:</label>
          <input
            type="text"
            name="Location"
            value={formData.Location}
            onChange={handleChange}
            required
          />
        </div>
          <button type="submit">{device ? "Update" : "Add"} Device</button>
          <button type="button" onClick={onCancel}>
            Cancel
          </button>
      </form>
    </div>
  );
};

export default AddEditDevice;