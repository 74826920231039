const MainInsights = () => {
  return (
    <>
      <div>
        <span className="spanheader">
          INSIGHTS <span style={{ fontSize: "8px" }}>(7 Days)</span>
        </span>
        <span className="spanheader2">View All</span>
        <div className="clear"></div>
        <hr />
        <div className="description">
          <div>
            <div className="div1">
              <p>Critical Issues</p>
              2 temperature alerts seen in last 4 hours
            </div>
            <div className="div2">2</div>
          </div>
          <div>
            <div className="div1">
              <p>Warnings</p>
              Average Temprature increased by 2% in last 24 hrs in comparison to
              last 24 hrs
            </div>
            <div className="div2">4</div>
          </div>
          <div>
            <div className="div1">
              <p>Precautionary</p>
              Lowest Flow recorded in last 20 mins
            </div>
            <div className="div2">6</div>
          </div>
          <div className="clear"></div>
        </div>
      </div>
    </>
  );
};

export default MainInsights;
