import React, { useState, useEffect } from "react";
import Sidebar from "../Components/Sidebar";

import DashboardHeader from "../Components/HeaderDashboard";
import { FaMinus, FaPlus, FaToolbox } from "react-icons/fa";
import AddEditDevice from "../Components/AddEditDevice";
import axios from "axios";
import Modal from 'react-modal';
import "../css/manageDevices.css";

//import { logError, logMessage, trackEvent } from "../Components/logtrigger.js";

Modal.setAppElement('#root'); // Set the root element for accessibility

const ManageDevices = () => {
    const PROXY_URL = process.env.REACT_APP_PROXY_URL || 'https://iot-backend-aug4h4hgh5fbdmeg.eastus-01.azurewebsites.net';
    const [isHidden, setIsHidden] = useState(false);
    const [devices, setDevices] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
  //  const [sessionId, setSessionId] = useState(null); // Added sessionId state

    const toggleClass = () => {
        setIsHidden((prev) => !prev);
        // trackEvent('Toggle Sidebar', { source: 'Frontend' }, sessionId);
    };

   const openModal = () => {
        setIsModalOpen(true);
        // trackEvent('Add_Device_Form_Clicked', { source: 'Frontend' }, sessionId);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedDevice(null);
    };

    const dashboardTitle = "Manage Devices";

    useEffect(() => {
        const fetchDevices = async () => {
            try {
                const response = await axios.get(PROXY_URL+'/devices', {withCredentials:true});
                setDevices(response.data);
                console.log(response.data);
                // logMessage('DeviceFetched', { source: 'Frontend' }, sessionId);
            } catch (error) {
                console.log(error);
                // logError(error, { source: 'Frontend' }, sessionId);
            } finally {
                setLoading(false);
            }
        };
        fetchDevices();
    }, []);

    const handleAddDevice = async (device) => {
        try {
            const response = await axios.post(PROXY_URL+"/devices", { device }, {withCredentials:true});
            setDevices([...devices, response.data]);
            // logMessage('DeviceAdded', { source: 'Frontend' }, sessionId);
            // trackEvent('Add_Device_Clicked', { source: 'Frontend', DeviceId: device.deviceId }, sessionId);
            closeModal();
        } catch (error) {
            setError("Error adding device");
            console.log(error);
            // logError(error, { source: 'Frontend' }, sessionId);
        }
    };

    const handleEditDevice = async (device) => {
        try {
            const response = await axios.put(PROXY_URL+`/devices/${device.deviceId}`, { device }, {withCredentials:true});
            setDevices(devices.map((d) =>
                d.deviceId === device.deviceId ? response.data : d
            ));
            closeModal();
            // trackEvent('Edit_Device_Clicked', { source: 'Frontend', DeviceId: device.deviceId }, sessionId);
            // logMessage('DeviceEdited', { source: 'Frontend' }, sessionId);
        } catch (error) {
            setError("Error editing device");
            console.log(error);
            // logError(error, { source: 'Frontend' }, sessionId);
        }
    };

    const handleDeleteDevice = async (deviceId) => {
        if (window.confirm("Are you sure you want to delete this device?")) {
            try {
                await axios.delete(PROXY_URL+`/devices/${deviceId}`, {withCredentials:true});
                setDevices(devices.filter((device) => device.deviceId !== deviceId));
                // trackEvent('Delete_Device_Clicked', { source: 'Frontend', DeviceId: deviceId }, sessionId);
                // logMessage('DeviceDeleted', { source: 'Frontend' }, sessionId);
            } catch (error) {
                setError("Error deleting device");
                console.log(error);
                // logError(error, { source: 'Frontend' }, sessionId);
            }
        }
    };

    const handleSelectDevice = (device) => {
        setSelectedDevice(device);
        openModal();
    };

    return (
        <div className="App">
            <div className="page-wrapper">
                <Sidebar isHidden={isHidden} toggleClass={toggleClass} />
                <div className={isHidden ? "rightblock w-90" : "rightblock"}>
                    <div className="top-card">
                        <DashboardHeader title={dashboardTitle} icon={<FaToolbox />} />
                    </div>
                    <div className="p20 w-80 mauto">
                        <div
                            id="add"
                            className="f20 cursorpointer m-10"
                            onClick={openModal}
                        >
                            {isModalOpen ? (
                                <FaMinus className="icontop f18" />
                            ) : (
                                <FaPlus className="icontop f18" />
                            )}{" "}
                            Add a Device.
                        </div>
                        <div className="clear"></div>
                        <div className="device-list">
                            <div>
                                <div className="w-100">
                                    {error && <p className="error-message">{error}</p>}
                                    <Modal
                                        isOpen={isModalOpen}
                                        onRequestClose={closeModal}
                                        contentLabel="Add/Edit Device"
                                    >
                                        <AddEditDevice
                                            device={selectedDevice}
                                            onSave={selectedDevice ? handleEditDevice : handleAddDevice}
                                            onCancel={closeModal}
                                        />
                                    </Modal>
                                </div>
                                <div className="device-head">
                                    <div className="device-details">
                                        <h4 style={{ fontWeight: "bolder" }}>Device Name</h4>
                                        <p>Status</p>
                                        <p>Location</p>
                                        <p>Area</p>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                {devices.map((device) => (
                                    <div className="device-item" key={device.deviceId}>
                                        <div className="device-details">
                                            <h4>{device.deviceId}</h4>
                                            <p>{device.status}</p>
                                            <p>{device.etag}</p>
                                        </div>
                                        <div className="device-actions">
                                            <div>
                                                <button onClick={() => handleSelectDevice(device)}>Edit</button>
                                            </div>
                                            <div>
                                                <button className="bg-red" onClick={() => handleDeleteDevice(device.deviceId)}>Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ManageDevices;