import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import DashboardFinal from './Pages/Dashboard';

const LoginButton = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  if (isAuthenticated) {
    return <DashboardFinal />; // Hide the login button if the user is already authenticated
  }

  return (
    <div style={{ margin: "auto", width: "200px", textAlign: "center", marginTop: "200px" }}>
      <button onClick={() => loginWithRedirect()} className='cursorpointer p20 w-100'>Log In</button>
      <p>Don't have an account? <Link to="/signup">Sign up</Link></p> {/* Add a link to sign up page */}
    </div>
  );
};

export default LoginButton;
