import axios from "axios";
const PROXY_URL = process.env.REACT_APP_PROXY_URL;

//logging functions
const logMessage = async (message, properties) => {
    try {
        await axios.post(PROXY_URL+'/log-message', { message, properties }, {withCredentials:true});
        console.log(message, properties);
    } catch (error) {
        console.error('Failed to log message:', error.message);
    }
};

const logError = async (error, properties) => {
    try {
        await axios.post(PROXY_URL+'/log-error', { error, properties }, {withCredentials:true});
        console.log(error, properties);
    } catch (err) {
        console.error('Failed to log error:', err.message);
    }
};

const trackEvent = async (event, properties) => {
    try {
        await axios.post(PROXY_URL+'/track-event', { event, properties }, {withCredentials:true});
        console.log(event, properties);
    } catch (error) {
        console.error('Failed to track event:', error.message);
    }
};
//logging functions closed

export { logMessage, logError, trackEvent };
