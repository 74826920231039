// src/components/BuildingChart.js

import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BuildingChart = () => {
  const [selectedOption, setSelectedOption] = useState('pH Level');

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const data = {
    labels: ['Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May'],
    datasets: [
      {
        label: selectedOption,
        data: selectedOption === 'pH' ? [1, 0.5, 3, 2, 2.5, 3.5, 5.4, 4.4] : 
              selectedOption === 'Flow' ? [30, 50, 40, 35, 25, 40, 28, 32] : 
              selectedOption === 'accumulatedrecycledvolume' ? [300, 500, 400, 250, 350, 280, 325] : 
              selectedOption === 'Temperature' ? [100, 85, 50, 75, 110, 150, 125] : 
              [100, 150, 75, 120, 70, 65, 55, 40],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      title: {
        display: true,
        text: `Building Data: ${selectedOption}`,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div>
      <div className="graphheader">
        <span className='spanheader'>Parameter Overview</span>
        <select value={selectedOption} onChange={handleChange}>
          <option value="pH">pH Level</option>
          <option value="Flow">Flow</option>
          <option value="accumulatedrecycledvolume">ARV</option>
          <option value="Temperature">Temperature</option>
          <option value="Turgidity">Turgidity</option>
        </select>
        <div style={{clear: "both"}}></div>
      </div>
      <Bar data={data} options={options} />
    </div>
  );
};

export default BuildingChart;
