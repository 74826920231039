import BuildingChart from "./BuildingChart";

const ParameterGraph = () => {
  return (
    <>
      <div>
        <div className="App-header p10">
          <BuildingChart />
        </div>
      </div>
    </>
  );
};

export default ParameterGraph;
