import React, { useState, useMemo, useEffect } from "react";
import { GoogleMap, Marker, useLoadScript, InfoWindow } from "@react-google-maps/api";
import { trackEvent } from "../Components/logtrigger.js";

const MapGoogle = ({ onMarkerClick, selectedArea, selectedLocation, setSelectedLocation }) => {
  const [selectedMarkerId, setSelectedMarkerId] = useState(null);

  const handleMarkerClick = (location) => {
    onMarkerClick(location);
    setSelectedLocation(location);
    setSelectedMarkerId(location.id);

    trackEvent('Marker_Clicked', { source: 'Frontend', location: location.locationName, id: location.id });
  };

  const locations = [
    {
      locationName: "Surrey",
      id: "Device 1",
      position: { lat: 49.164519, lng: -122.877659 },
      name: "MacColl Crescent",
    },
    {
      locationName: "Surrey",
      id: "Device 2",
      position: { lat: 49.139200, lng: -122.797655 },
      name: "East Newton North",
    },
    {
        locationName: "Richmond",
      id: "Device 1",
      position: { lat: 49.153478, lng: -123.129304 },
      name: "Lunen Road",
    },
    {
        locationName: "Richmond",
      id: "Device 2",
      position: { lat: 49.179467, lng: -123.097303 },
      name: "Dallyn Road",
    },
  ];

  const filteredLocations = useMemo(() => {
      return locations.filter(location => location.locationName === selectedArea);
  }, [selectedArea]);

  const center = useMemo(() => {
    if (selectedLocation) {
      return selectedLocation.position;
    }
    // Default center based on the selected area
    if (selectedArea === "Surrey") {
      return { lat: 49.164519, lng: -122.867655 };
    } else if (selectedArea === "Richmond") {
      return { lat: 49.103478, lng: -123.129304 };
    }
    return { lat: 49.164519, lng: -122.867655 };
  }, [selectedArea, selectedLocation]);

  useEffect(() => {
    if (!selectedLocation) {
      const defaultLocation = filteredLocations.find(location => location.id === `Device 1`);
      setSelectedLocation(defaultLocation);
      setSelectedMarkerId(defaultLocation.id);
    }
  }, [filteredLocations, selectedLocation, selectedArea]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || 'AIzaSyCrOVdQxa749hZwrgzWcQd2IFGp5tHxLf4',
  });
  return (
    <div>
      <div className="clear"></div>
      <div className="b-shadow mtop-20 bw5">
        {!isLoaded ? (
          <h1>Loading...</h1>
        ) : (
          <GoogleMap mapContainerClassName="map-container" center={center} zoom={10}>
            {filteredLocations.map((location) => (
              <Marker
                key={location.id}
                position={location.position}
                onClick={() => handleMarkerClick(location)}
                icon={{
                  url: `https://maps.google.com/mapfiles/ms/micons/purple-dot.png`,
                  scaledSize: selectedMarkerId === location.id ? new window.google.maps.Size(50, 50) : new window.google.maps.Size(30, 30),
                }}
              >
                {selectedMarkerId === location.id && selectedLocation && (
                  <InfoWindow
                  position={selectedLocation.position}
                  onCloseClick={() => {
                    setSelectedLocation(null); // Close the dialog box
                    setSelectedMarkerId(null); // Reset selected marker
                  }}
                >
                    <div>
                      <h3>{selectedLocation.name}</h3>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            ))}
          </GoogleMap>
        )}
      </div>
    </div>
  );
};

export default MapGoogle;
