import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  FaArrowLeft,
  FaArrowRight,
  FaCog,
  FaEye,
  FaHome,
  FaSourcetree,
  FaToolbox,
  FaUser,
} from "react-icons/fa";

const Sidebar = (props) => {
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState("/");

  useEffect(() => {
    setSelectedMenu(location.pathname);
  }, [location.pathname]);

  const toggleClass = () => {
    props.toggleClass(); // Call toggleClass function from props to update isHidden
  };

  return (
    <div className={props.isHidden ? "hideblock leftblock" : "leftblock"}>
      <div className={`left f20 m-15 ${
            props.isHidden ? "hide" : ""
          }`}> <FaEye style={{position:"relative", top:"3"}} /> Surveil</div>
      <div
        className={
          props.isHidden ? "hideblockarrow leftblockarrow" : "leftblockarrow"
        }
        onClick={toggleClass}
      >
        {props.isHidden ? <FaArrowRight /> : <FaArrowLeft />}
      </div>
      <div className="clear"></div>
      <hr style={{borderStyle:"dotted"}} />
      <div className="myMenu mtop-20">
        <Link
          to="/"
          className={`${selectedMenu === "/" ? "selected" : ""} ${
            props.isHidden ? "selectedHide" : ""
          }`}
        >
          <FaHome className="icontop" />{" "}
          <span className={`${props.isHidden ? "hide" : "show"} mrl-10`}>
            Dashboard
          </span>
        </Link>
        <Link
          to="/threshold"
          className={`${selectedMenu === "/threshold" ? "selected" : ""} ${
            props.isHidden ? "selectedHide" : ""
          }`}
          title="Set Alerts"
        >
          <FaSourcetree className="icontop" />{" "}
          <span className={`${props.isHidden ? "hide" : "show"} mrl-10`}>
            Set Alerts
          </span>
        </Link>
        <Link
          to="/manageDevices"
          className={`${selectedMenu === "/manageDevices" ? "selected" : ""} ${
            props.isHidden ? "selectedHide" : ""
          }`}
        >
          <FaToolbox className="icontop" />{" "}
          <span className={`${props.isHidden ? "hide" : "show"} mrl-10`}>
            Manage Devices
          </span>
        </Link>
        <Link
          to="/manageUsers"
          className={`${selectedMenu === "/manageUsers" ? "selected" : ""} ${
            props.isHidden ? "selectedHide" : ""
          }`}
        >
          <FaUser className="icontop" />{" "}
          <span className={`${props.isHidden ? "hide" : "show"} mrl-10`}>
            Manage Users
          </span>
        </Link>
      </div>
      <div className="cursorpointer settings">
        <a href="./GeneralSettings" style={{fontWeight:"bold",textDecoration:"none", color:"#fff"}}>
          <span className={props.isHidden ? "hide" : "show"}>
            General Settings
          </span>{" "}
          <FaCog className="icontop" />
        </a>
      </div>
    </div>
  );
};

export default Sidebar;
