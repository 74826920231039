import { useState, useEffect, useCallback } from "react";
import Sidebar from "../Components/Sidebar";
import MapGoogle from "../Components/Map";
import DashboardHeader from "../Components/HeaderDashboard";
import ParameterGraph from "../Components/Graph";
import MainInsights from "../Components/Insights";
import SalesTotal from "../Components/SalesTotal";
import moment from "moment";
import { FaViacoin } from "react-icons/fa";
import DropDownSelect from "../Components/DropDown";
import TimeModeSelect from "../Components/TimeModeSelect"; // Import new component
import DatePicker from "react-datepicker"; // Import a date picker library
import "react-datepicker/dist/react-datepicker.css";

const DashboardFinal = () => {
  const [isHidden, setIsHidden] = useState(null);
  const [selectedArea, setSelectedArea] = useState("Surrey");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [timeMode, setTimeMode] = useState("Realtime"); // Add state for time mode
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const initialDeviceData = {
    temperature: 0,
    humidity: 0,
    phLevel: 0,
    turgidity: 0,
    EventEnqueuedUtcTime: moment.now(),
  };
  const [deviceData, setDeviceData] = useState(initialDeviceData);
  const [chartData, setChartData] = useState([initialDeviceData]);

  const handleLocationChange = (location) => {
    setSelectedArea(location);
    setDeviceData(initialDeviceData);
    setChartData([initialDeviceData]);
  };

  const toggleClass = () => {
    setIsHidden((prev) => !prev);
  };

  const updateLocationData = (location) => {
    let newDeviceData;
    let newChartData;
    newDeviceData = initialDeviceData;
    newChartData = [initialDeviceData];

    setDeviceData(newDeviceData);
    setChartData(newChartData);
  };

  const handleMarkerClick = useCallback((location) => {
    setSelectedLocation(location);
    updateLocationData(location);
  }, []);

  useEffect(() => {
    const defaultLocation = {
      id: "Device 1",
      position: { lat: 49.164519, lng: -122.867655 },
      name: "MacColl Crescent",
    };
    setSelectedLocation(defaultLocation);
  }, []);

  const dashboardTitle = "Dashboard";

  return (
    <div className="App">
      <div className="page-wrapper">
        <Sidebar isHidden={isHidden} toggleClass={toggleClass} />
        <div className={isHidden ? "rightblock w-90" : "rightblock"}>
          <div className="top-card">
            <DashboardHeader title={dashboardTitle} icon={<FaViacoin />} />
          </div>
          <div className="clear"></div>
          <div className="p20">
            <div className="content-card-left bg-none">
              <div
                style={{
                  lineHeight: "35px",
                  marginTop: "5px",
                  color: "#737171",
                }}
              >
                <div className="left">
                  <DropDownSelect onSelectChange={handleLocationChange} />
                </div>
              </div>
              <div className="right mtop-10 f16 plr20">
                Showing :{" "}
                <span style={{ fontWeight: "bold" }}>
                  {selectedLocation?.name}
                </span>
              </div>
              <div className="clear"></div>
              <div>
                <MapGoogle
                  onMarkerClick={handleMarkerClick}
                  selectedArea={selectedArea}
                  selectedLocation={selectedLocation}
                  setSelectedLocation={setSelectedLocation}
                />
              </div>
              <div className="mtop-20 bg-white p10 b-shadow">
                <ParameterGraph />
              </div>
            </div>
            <div className="content-card-right bg-none">
              <div className="w-100 left plr20 pt10">
                <div className="left mrl-20">
                  <TimeModeSelect onSelectChange={setTimeMode} />
                </div>
                {timeMode === "TimeRange" && (
                  <div
                    style={{
                      display: "flex",
                      paddingLeft: "10px", lineHeight: "35px"
                    }}
                  >
                    from: 
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showTimeSelect
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="MMMM d, yyyy h:mm aa"
                    /> to: 
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      showTimeSelect
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="MMMM d, yyyy h:mm aa"
                    />
                  </div>
                )}
              </div>
              <div className="clear"></div>
              <div>
                <SalesTotal
                  deviceData={deviceData}
                  chartData={chartData}
                  selectedLocation={selectedLocation}
                  setDeviceData={setDeviceData}
                  setChartData={setChartData}
                />
              </div>
              <div className="p20">
                <div className="bg-white b-shadow" style={{ padding: "10px" }}>
                  <MainInsights />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardFinal;
