const DropDownSelect = ({ onSelectChange }) => {
  //created function to load map for separate dd selection
  const handleSelectChange = (event) => {
    const selectedLocation = event.target.value;
    onSelectChange(selectedLocation);
    console.log(selectedLocation);
  };
  return (
    <>
      <div>
        <div className="left f16">Area </div>
        <div className="right">
          <select
            className="myselectbox b-shadow mrl-10"
            onChange={handleSelectChange}
          >
            <option value="Surrey">Surrey</option>
            <option value="Richmond">Richmond</option>
          </select>
        </div>
      </div>
    </>
  );
};

export default DropDownSelect;
