// TimeModeSelect.js
import React from 'react';

const TimeModeSelect = ({ onSelectChange }) => {
  return (
    <select onChange={(e) => onSelectChange(e.target.value)} className='myselectbox1'>
      <option value="Realtime">Realtime</option>
      <option value="TimeRange">Time Range</option>
    </select>
  );
};

export default TimeModeSelect;
