import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import App from './App';
import './css/index.css';
import axios from 'axios';

// Function to get URL parameters
const getQueryParams = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

const redirectUri = window.location.origin;

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_PROXY_URL || 'https://iot-backend-aug4h4hgh5fbdmeg.eastus-01.azurewebsites.net', // Your backend URL
  withCredentials: true, // Allow sending cookies with requests
});

// Add a request interceptor
axiosInstance.interceptors.request.use((config) => {
  const sessionId = localStorage.getItem('sessionId');
  if (sessionId) {
    config.headers['Session-ID'] = sessionId;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

const Main = () => {
  console.log(process.env.WEBSITE_AUTH_LOGOUT_PATH);
  console.log(process.env.REACT_APP_PROXY_URL);
  console.log('MYLOG:', process.env.APPLICATION_INSIGHTS_INSTRUMENTATION_KEY);
  console.log('MYLOG1:', process.env.AMITEV);
  console.log('MYLOG1:', process.env.AMITEVV);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [domain, setDomain] = useState(null);
  const [clientId, setClientId] = useState(null);

  useEffect(() => {
    const storedDomain = getQueryParams('domain') || localStorage.getItem('domain');
    if (storedDomain) {
      if (storedDomain === process.env.REACT_APP_AUTH0_TT || storedDomain === 'mevrsa1' ) {
        setDomain(process.env.REACT_APP_AUTH0_T || 'mevrsa1.us.auth0.com');
        setClientId(process.env.REACT_APP_AUTH0_T_CREDS || 'ojwdo82SFoY2wHoX4sV9YVoeJErqsXVr');
      } else if (storedDomain === process.env.REACT_APP_AUTH0_TT1 || storedDomain === 'mevrsa2' ) {
        setDomain(process.env.REACT_APP_AUTH0_T1 || 'mevrsa2.us.auth0.com');
        setClientId(process.env.REACT_APP_AUTH0_T1_CREDS || 'zbhL74dFUEQRseObh2L8Hrg5xfzseEJ8');
      } else {
        return null;
      }
      localStorage.setItem('domain', storedDomain);
    }
  }, []);

  if (!domain || !clientId) {
    return <div>Loading...</div>;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={redirectUri}
    >
      <App />
    </Auth0Provider>
  );
};

ReactDOM.render(
  <BrowserRouter>
    <Main />
  </BrowserRouter>,
  document.getElementById('root')
);

export { axiosInstance };
